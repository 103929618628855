import _csharp from "./csharp.js";
var exports = {};
var refractorCsharp = _csharp;
exports = aspnet;
aspnet.displayName = "aspnet";
aspnet.aliases = [];

function aspnet(Prism) {
  Prism.register(refractorCsharp);
  Prism.languages.aspnet = Prism.languages.extend("markup", {
    "page-directive": {
      pattern: /<%\s*@.*%>/i,
      alias: "tag",
      inside: {
        "page-directive": {
          pattern: /<%\s*@\s*(?:Assembly|Control|Implements|Import|Master(?:Type)?|OutputCache|Page|PreviousPageType|Reference|Register)?|%>/i,
          alias: "tag"
        },
        rest: Prism.languages.markup.tag.inside
      }
    },
    directive: {
      pattern: /<%.*%>/i,
      alias: "tag",
      inside: {
        directive: {
          pattern: /<%\s*?[$=%#:]{0,2}|%>/i,
          alias: "tag"
        },
        rest: Prism.languages.csharp
      }
    }
  }); // Regexp copied from prism-markup, with a negative look-ahead added

  Prism.languages.aspnet.tag.pattern = /<(?!%)\/?[^\s>\/]+(?:\s+[^\s>\/=]+(?:=(?:("|')(?:\\[\s\S]|(?!\1)[^\\])*\1|[^\s'">=]+))?)*\s*\/?>/i; // match directives of attribute value foo="<% Bar %>"

  Prism.languages.insertBefore("inside", "punctuation", {
    directive: Prism.languages.aspnet["directive"]
  }, Prism.languages.aspnet.tag.inside["attr-value"]);
  Prism.languages.insertBefore("aspnet", "comment", {
    "asp-comment": {
      pattern: /<%--[\s\S]*?--%>/,
      alias: ["asp", "comment"]
    }
  }); // script runat="server" contains csharp, not javascript

  Prism.languages.insertBefore("aspnet", Prism.languages.javascript ? "script" : "tag", {
    "asp-script": {
      pattern: /(<script(?=.*runat=['"]?server\b)[^>]*>)[\s\S]*?(?=<\/script>)/i,
      lookbehind: true,
      alias: ["asp", "script"],
      inside: Prism.languages.csharp || {}
    }
  });
}

export default exports;